<template>
  <div id="about">
    <h2>About / FAQ</h2>
    <h3>Why</h3>
    <p>Just because.</p>
    <h3>Why??</h3>
    <p>The longer answer is that I watched <a href="https://www.youtube.com/watch?v=K6jkmUYEIdQ">Is Cereal Just Soup? (Food Debate)</a> by Mythical Kitchen and got inspired to create this. This is heavily influenced by <a href="https://isthisasandwich.netlify.app/">isthisasandwich</a> by <a href="https://twitter.com/sarah_edo">@sarah_edo</a>. But with way uglier execution since I'm not Sarah or even know what I am doing. Also I think almost everything can be a soup if we try hard enough.</p>
    <h3>Image credits</h3>
    <p>All the stunning pictures are from <a href="https://unsplash.com/">unsplash</a>.</p>
    <ul>
      <li>Noodle Soup: <a href="https://unsplash.com/photos/PyYfyE8s3Fc">Photo by Ke Vin on Unsplash</a></li>
      <li>Cereal: <a href="https://unsplash.com/photos/EV6C1LjH1Lk">Photo by Nyana Stoica on Unsplash</a></li>
      <li>Gazpacho: <a href="https://unsplash.com/photos/M6baxXgSos8">Photo by Sara Dubler on Unsplash</a></li>
      <li>Ramen: <a href="https://unsplash.com/photos/lbxLlgi_8_w">Photo by j on Unsplash</a></li>
      <li>Fondue: <a href="https://unsplash.com/photos/vo7GGTh6sXM">Photo by angela pham on Unsplash</a></li>
      <li>Steak: <a href="https://unsplash.com/photos/UeYkqQh4PoI">Photo by Alexandru-Bogdan Ghita on Unsplash</a></li>
      <li>Porridge: <a href="https://unsplash.com/photos/7YLe87dcrm8">Photo by Melissa Belanger on Unsplash</a></li>
      <li>Smoothie: <a href="https://unsplash.com/photos/DSxurmhrfuc">Photo by Francesca Hotchin on Unsplash</a></li>
    </ul>
    <h3>Tech</h3>
    <p>Build with <a href="https://vuejs.org/">Vue.js</a> and <a href="https://myliang.github.io/fish-ui/#/components/index">Fish-UI</a>.</p>
  </div>
</template>

<script>
export default {
  name: 'About',
}
</script>

<style scoped>
#about {
  padding: 8px;
}
</style>
