import Vue from 'vue'
import App from './App.vue'
import VueRouter from 'vue-router'
import FishUI from 'fish-ui'

import Main from './components/Main.vue'
import About from './components/About.vue'

Vue.use(VueRouter)
Vue.use(FishUI)

Vue.config.productionTip = false

const routes = [
  { path: '/', component: Main },
  { path: '/about', component: About }
]

const router = new VueRouter({
  routes // short for `routes: routes`
})

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
