<template>
    <fish-layout id="app">
      <template slot="header">
        <h1>Is it a soup <router-link to="/">🍲</router-link>?!</h1>
      </template>
      <router-view slot="content"></router-view>
      <span slot="footer">
        <a href='https://ko-fi.com/P5P413IOA'>Support Me on Ko-fi</a> or tell me how wrong I am on <a href="https://twitter.com/l33tname">Twitter</a> - <router-link to="/about">about</router-link>
      </span>
    </fish-layout>
</template>

<script>

export default {
  name: 'App',
}
</script>

<style>
h1 {
  font-size: 5em !important;
}
.fish.layout {
  text-align:center;
}
.header {
  margin-bottom: 1em;
}
.content {
  max-width: 800px;
  margin: 0 auto 0 auto;
}
.footer {
  margin-top:2em;
}
@media only screen and (max-width: 600px) {
  img {
    width: 300px;
  }
}
</style>
