<template>
  <div>
      <template v-if="!the_end">
        <div class="button-group">
          <fish-button id="yes" class="buttonspaceright" size="large" type="positive" v-on:click="yes">Yes</fish-button>
          <fish-button id="no" size="large" type="negative" v-on:click="no">No</fish-button>
        </div>
        <img :src="imgpath(current_soup.img)">
        <h3>{{current_soup.name}}</h3>
      </template>
      <template v-if="the_end">
        <h2>{{this.getResultMessage()}}</h2>
        <a href="https://twitter.com/intent/tweet?text=Do+you+know+what+a+soup+is?+I+do+🎉.+Play+it:+https://isitsoup.l33t.name/+%23isitsoup" target="_blank"><fish-button class="buttonspaceright" size="large" type="primary">Tweet</fish-button></a>
        <fish-button size="large" v-on:click="replay">Play again</fish-button>
      </template>
  </div>
</template>

<script>

export default {
  name: 'Main',
  created: function () {
    this.current_soup = this.soups[0]
  },
  data() {
    return {
      soups: [
        {img: "soup.jpg",     name: "Noodle Soup",},
        {img: "fondue.jpg",   name: "Fondue",     },
        {img: "cereal.jpg",   name: "Cereal",     },
        {img: "steak.jpg",    name: "Steak",      },
        {img: "gazpacho.jpg", name: "Gazpacho",   },
        {img: "ramen.jpg",    name: "Ramen",      },
        {img: "porridge.jpg", name: "Porridge",   },
        {img: "smoothie.jpg", name: "Smoothie",   },
      ],
      current_soup: null,
      currentIndex: 0,
      result: [],
      the_end: false,
    }
  },
  methods: {
    replay: function(){
      this.the_end = false
      this.result = []
      this.currentIndex = 0
      this.current_soup = this.soups[this.currentIndex];
    },
    imgpath: function(name) {
      return `/img/${name}`
    },
    yes: function () {
      this.result.push(1)
      this.nextItem()
    },
    no: function() {
      this.result.push(0)
      this.nextItem()
    },
    nextItem: function() {
      if(this.soups.length > (this.currentIndex + 1)){
        this.currentIndex++;
        this.current_soup = this.soups[this.currentIndex];
      } else {
        this.the_end = true
        console.log(this.result.join())
      }
    },
    getResultMessage: function() {
      let msg = "Who are you? The food police?"
      switch(this.result.join()){
        case "0,0,0,0,0,0,0,0":
          msg = "You are such a naysayer. Are you anti everything?"
          break
        case "1,1,1,1,1,1,1,1":
          msg = "Not everything is a soup in life..."
          break
        case "1,0,0,0,0,0,0,0":
          msg = "You are are a real soup purist."
          break
        case "0,0,0,1,0,0,0,0":
          msg = "Steak is not a soup. Are you a Food anarchist?"
          break
        case "1,0,1,0,1,1,0,0":
          msg = "This is the only sane solution!"
          break
        case "1,0,0,0,1,1,0,0":
          msg = "There is hope left for humanity! You chose correctly."
          break
        case "0,1,1,1,0,0,1,1":
          msg = "How can one person be so wrong."
          break
        default:
          if(this.result[1] == 1){
            msg = "Fondue a soup? So stupid, yet so brave."
          } else if(this.result[3] == 1){
            msg = "Steak a soup? So stupid, yet so brave."
          } else if(this.result[4] == 0){
            msg = "Do you even know what a gazpacho is or do you hate cold soup in general?"
          }
      }

      return msg
    },
  }
}
</script>

<style scoped>
h2 {
  font-size: 3em !important;
}
h3 {
  margin-top:0 !important;
}
.button-group {
  margin-bottom: 1em;
}
.buttonspaceright {
  margin-right:2em;
}

</style>
